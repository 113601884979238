<template>
    <div>
        <b-img :key="value" :alt="value" :src="value ? $baseUrl + value : null" :width="width" :height="height" @click="show"
            role="button" />
        <b-modal id="images_modal" hide-footer title="Select Image">
            <div v-if="images" class="w-100">
                <b-row class="w-100 m-0 p-0">
                    <b-col v-for="(img, index) in images" :key="index" cols="4" class="p-0 m-0" style="aspect-ratio: 1;">
                        <b-img class="w-100 h-100" :src="$baseUrl + img" :alt="img" fluid
                            :style="{ border: img == value ? '4px solid red' : '' }" role="button"
                            @click="select(img)"></b-img>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <b-overlay :show="isLoading" no-wrap fixed style="z-index:2000"></b-overlay>
    </div>
</template>
<script>
import axios from "axios";
import { getCookie, eraseCookie } from "../Api";
export default {
    name: "NImg",
    props: ["value", "width", "height"],
    methods: {
        select(img) {
            this.$emit('input', img)
            this.$bvModal.hide("images_modal");
        },
        async show() {
            if (!this.images) {
                await this.getImages();
            }
            this.$bvModal.show("images_modal");
        },
        async getImages() {
            this.isLoading = true;
            try {
                const res = await axios.get(this.$baseUrl + "/api/images", {
                    cache: false,
                    headers: {
                        secret: getCookie("secret")
                    }
                });
                this.images = res.data;
            } catch (error) {
                this.showError(error);
            }
            this.isLoading = false;
        },
        showError(error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.error && error.response.data.error == "unauthorized") {
                    eraseCookie("secret");
                    this.data = null;
                }
                this.$bvToast.toast(error.response.data.error, {
                    title: "Error",
                    variant: "danger",
                    solid: true
                })
            }
            else {
                this.$bvToast.toast(error.message, {
                    title: "Error",
                    variant: "danger",
                    solid: true
                });
            }
        }
    },
    data() {
        return {
            isLoading: false
        };
    },
    computed: {
        images: {
            get: function () {
                return this.$store.state.images;
            },
            set: function (newVal) {
                this.$store.state.images = newVal
            }
        }
    }
};
</script>