<template>
    <div>
        <b-icon :key="value" :icon="value" :style="{width: width,height:height,padding: padding,border:'1px solid gray'}" role="button"
            @click="show"></b-icon>
        <b-modal id="icons_modal" title="Icons" hide-footer>
            <b-row class="w-100 m-0 p-0">
                <b-col v-for="(icon, index) in iconList" :key="index" cols="2" class="p-0 m-0">
                    <b-card role="button" class="text-center m-1 p-2" no-body
                        :style="{ border: icon == value ? '4px solid red' : '' }" @click="select(icon)">
                        <b-icon :icon="icon" class="m-auto"></b-icon>
                    </b-card>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import iconList from "../assets/iconList";
export default {
    name: "NIcon",
    props: ["value", "width", "height","padding"],
    methods: {
        select(icon) {
            this.$emit('input', icon)
            this.$bvModal.hide("icons_modal");
        },
        async show() {
            this.$bvModal.show("icons_modal");
        },
    },
    data() {
        return {
            iconList
        };
    }
};
</script>