<template>
  <div>
    <div
      v-if="!data"
      class="vw-100 vh-100 d-flex flex-column align-items-center justify-content-center"
    >
      <b-spinner></b-spinner>
    </div>
    <div
      v-else
      :style="{backgroundColor:data.themes.items[data.themes.selectedIndex].background,color:data.themes.items[data.themes.selectedIndex].text}"
    >
      <div
        class="p-2"
        :style="{backgroundColor:data.themes.items[data.themes.selectedIndex].primary,color:data.themes.items[data.themes.selectedIndex].onPrimary}"
      >
        <b-row class="ii-header m-0 p-0" align-v="center">
          <div class="w-auto" style="max-width: 75%;">
            <b-icon icon="telephone-fill"></b-icon>
            {{ data.header.top.text_1[lang] }}
            <a
              :href="'tel:' + data.header.top.button_1[lang]"
              class="link"
            >{{ data.header.top.button_1[lang] }}</a>
          </div>
          <div class="m-auto w-auto"></div>
          <b-button
            pill
            class="ii-button-lang"
            @click="() => { if (lang == 'en') { lang = 'fr' } else { lang = 'en' } }"
          >
            <span :style="{ opacity: lang == 'fr' ? 1 : 0.5 }">FR</span>
            <span :style="{ opacity: lang == 'en' ? 1 : 0.5 }">EN</span>
          </b-button>
        </b-row>
      </div>
      <div class="ii-title p-3">
        INFINITE
        <span :style="{color:data.themes.items[data.themes.selectedIndex].primary}">INSPECT</span>
      </div>
      <div v-if="data.section_1.enable" class="top-slider">
        <b-carousel fade :interval="4000" class="top-slider">
          <b-carousel-slide
            v-for="(val, key) in data.section_1.slider"
            :key="key"
            class="top-slider"
          >
            <template #img>
              <div
                :style="{
                backgroundImage: 'url(' + $baseUrl + val + ')',
                height: '100%',
                width: '100%',
                backgroundPosition: 'center top',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              }"
              ></div>
            </template>
          </b-carousel-slide>
        </b-carousel>
        <div class="ov-s1">
          <h1 class="ov-s1-h1" style="font-weight: 400;" v-html="data.section_1.card.text_1[lang]"></h1>
          <h1 class="ov-s1-h1" style="font-weight: 700;" v-html="data.section_1.card.text_2[lang]"></h1>
          <p class="ov-s1-p" v-html="data.section_1.card.text_3[lang]"></p>
          <b-row class="m-0 p-0">
            <b-button
              pill
              size="lg"
              class="mt-1"
              :style="{backgroundColor:data.themes.items[data.themes.selectedIndex].background,color:data.themes.items[data.themes.selectedIndex].primary,borderColor:data.themes.items[data.themes.selectedIndex].primary,borderWidth:'2px'}"
              :href="'tel:' + data.section_1.card.button_1[lang]"
            >
              {{
              data.section_1.card.button_1[lang] }}
            </b-button>
            <div style="margin: auto;min-width: 8px;"></div>
            <b-button
              pill
              size="lg"
              class="mt-1"
              :style="{backgroundColor:data.themes.items[data.themes.selectedIndex].primary,color:data.themes.items[data.themes.selectedIndex].onPrimary,borderColor:data.themes.items[data.themes.selectedIndex].background,borderWidth:'0px'}"
            >{{ data.section_1.card.button_2[lang] }}</b-button>
          </b-row>
        </div>
      </div>
      <div v-if="data.section_2.enable" class="s2">
        <b-row class="m-0 p-0">
          <b-col cols="12" class="p-3 m-0" md="4">
            <b-img :src="$baseUrl + data.section_2.img" fluid-grow rounded />
          </b-col>
          <b-col cols="12" class="p-3 m-0" md="8">
            <h2
              class="m-0 p-0"
              style="font-weight: 400;font-family: 'Titillium Web', sans-serif;font-size: 31px;line-height: 1.1;letter-spacing: 0px;"
              v-html="data.section_2.top.text_1[lang]"
            ></h2>
            <p
              style="padding-top: 8px;font-weight: 400;font-family: 'Open Sans', sans-serif;font-size: 13px;line-height: 1.5;letter-spacing: 0px;"
              v-html="data.section_2.top.text_2[lang]"
            ></p>
          </b-col>
        </b-row>
        <b-row class="m-0 p-0">
          <b-col cols="12" class="p-3 m-0" md="4">
            <h1
              style="font-weight: 400;font-style: normal;font-family: 'Titillium Web', sans-serif;font-size: 34px;line-height: 1.3;letter-spacing: 0px;"
              v-html="data.section_2.side.text_1[lang]"
            ></h1>
            <b-button
              pill
              size="lg"
              :style="{backgroundColor:data.themes.items[data.themes.selectedIndex].primary,color:data.themes.items[data.themes.selectedIndex].onPrimary}"
            >{{ data.section_2.side.button_1[lang] }}</b-button>
          </b-col>
          <b-col cols="12" class="p-0 m-0" md="8">
            <b-row class="m-0 p-0">
              <b-col
                v-for="(item, index) in data.section_2.items"
                cols="12"
                class="p-3 m-0"
                md="6"
                :key="index"
              >
                <b-row class="m-0 p-0">
                  <div
                    style="width: 70px;height: 70px;border-radius: 8px;padding: 8px;"
                    :style="{backgroundColor:data.themes.items[data.themes.selectedIndex].primary,color:data.themes.items[data.themes.selectedIndex].onPrimary}"
                  >
                    <b-icon :icon="item.icon" class="w-100 h-100"></b-icon>
                  </div>
                  <b-col class="py-2">
                    <h3
                      style="
                    font-weight: 400;
                    font-style: normal;
                    font-family: 'Titillium Web', sans-serif;
                    font-size: 23px;
                    line-height: 0.8;
                    letter-spacing: 0px;"
                    >{{ item.title[lang] }}</h3>
                    <p
                      style="
                    padding-top: 4px;
                    font-weight: 400;
                    font-style: normal;
                    font-family: 'Open Sans', sans-serif;
                    color: #828282;
                    font-size: 16px;
                    line-height: 1.4;
                    letter-spacing: 0px;"
                    >{{ item.body[lang] }}</p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-if="data.section_3.enable" class="s3">
        <b-row class="w-100 p-0 m-0">
          <b-col
            sm="12"
            md
            class="p-0 m-0"
            v-for="(item, index) in data.section_3.items"
            :key="'s3_' + index"
          >
            <router-link :to="{path:'/service?index='+index}">
              <div
                :style="{ backgroundImage: 'url(' + $baseUrl + item.img + ')', height: '400px', backgroundPosition: 'center top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }"
              >
                <h3
                  class="px-1"
                  style="background: linear-gradient(0deg, #00000000 0%, #00000077 30%,  #00000077 100%);padding-bottom: 20px;padding-top: 8px;color: #ffffff;text-shadow: 1px 1px 2px black;font-family: 'Titillium Web';"
                  v-html="item.title[lang]"
                ></h3>
              </div>
            </router-link>
          </b-col>
        </b-row>
      </div>
      <div v-if="data.section_4.enable" class="s4 text-center mt-3 px-3">
        <h2
          style="font-size: font-size: 31px;font-weight:400 ;font-style:normal;font-family: 'Titillium Web', sans-serif"
          v-html="data.section_4.title[lang]"
        ></h2>
        <p
          style="font-size: 12px;line-height: 1.4;font-style: normal;font-family: 'Open Sans', sans-serif;color: #828282;"
          v-html="data.section_4.subtitle[lang]"
        ></p>
        <b-row class="m-0 p-0">
          <b-col
            sm="12"
            md="6"
            lg="3"
            v-for="(item, index) in data.section_4.items"
            :key="'price_' + index"
            class="mb-3"
          >
            <div
              style="border-style: solid;border-left-width: 1px;border-right-width: 1px;border-bottom-width: 1px;transition-duration: 0.4s;"
              :style="{borderColor: data.themes.items[data.themes.selectedIndex].text}"
            >
              <div
                style="padding-top: 20px;padding-bottom: 20px;padding-left: 20px;padding-right: 20px;"
                :style="{background: data.themes.items[data.themes.selectedIndex].text}"
              >
                <div
                  style="padding-top: 10px;padding-bottom: 10px;background-color: #242424;border-top-left-radius: 30px;border-top-right-radius: 30px;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;transition-duration: 0.4s;color:#ffffff;font-weight: 400;font-style: normal;font-family: 'Titillium Web', sans-serif;color: #ffffff;font-size: 18px;line-height: 1.2;letter-spacing: 0px;"
                  v-html="item.title[lang]"
                ></div>
              </div>
              <p
                style="font-weight: 700;font-style: normal;font-family: 'Titillium Web', sans-serif;font-size: 30px;line-height: 0.9;letter-spacing: 0px;padding-left: 16px;padding-right: 16px;padding-top: 40px;padding-bottom: 40px;"
                v-html="item.price[lang]"
              ></p>
              <p
                style="font-weight: 400;font-style: normal;font-family: 'Titillium Web', sans-serif;font-size: 18px;line-height: 1.2;letter-spacing: 0px;"
                v-html="item.name[lang]"
              ></p>
              <div style="background: #000000;width: 64px;height: 1px;" class="my-2 mx-auto"></div>
              <p
                style="font-weight: 400;font-style: normal;font-family: 'Titillium Web', sans-serif;font-size: 14px;line-height: 1.2;letter-spacing: 0px;"
                v-html="item.desc[lang]"
              ></p>
              <b-button
                pill
                class="mb-4"
                size="lg"
                :style="{backgroundColor:data.themes.items[data.themes.selectedIndex].primary,color:data.themes.items[data.themes.selectedIndex].onPrimary}"
              >{{ item.button[lang] }}</b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="data.section_5.enable" class="s5">
        <div style="display:flex;width:100%;flex-wrap: wrap;">
          <div
            class="s5-img"
            :style="{ aspectRatio: 1, width: getWidthPersention(data.section_5.images, index) + '%', cursor: 'pointer', position: 'relative' }"
            v-for="(val, index) in  data.section_5.images"
            :key="'s5_' + index"
            @click="showGaloryModal(val)"
          >
            <b-img class="w-100 h-100" :src="$baseUrl + val" :alt="val" />
            <div
              class="s5-img-overlay"
              :style="{backgroundColor:data.themes.items[data.themes.selectedIndex].primary+'88'}"
            >
              <b-icon
                style=" position: absolute;top: 50%;left: 50%;-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);text-align: center;"
                :style="{color:data.themes.items[data.themes.selectedIndex].onPrimary}"
                icon="zoom-in"
              ></b-icon>
            </div>
          </div>
        </div>
      </div>
      <div v-if="data.section_6.enable" class="s6">
        <b-row class="m-0 p-0" align-h="center">
          <b-card
            v-for="(item,index) in data.section_6.items"
            :key="index"
            :title="item.title[lang]"
            :img-src="$baseUrl + item.img"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="m-1 p-0"
          >
            <b-card-text>{{ item.body[lang] }}</b-card-text>
          </b-card>
        </b-row>
      </div>
      <div v-if="data.section_7.enable" class="s7">
        <b-row class="m-0 p-0" align-h="center">
          <b-card
            v-for="(item,index) in data.section_7.items"
            :key="index"
            :title="item.title[lang]"
            :img-src="$baseUrl + item.img"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="m-1 p-0"
          >
            <b-card-text>{{ item.body[lang] }}</b-card-text>
          </b-card>
        </b-row>
      </div>
      <div v-if="data.footer.enable" class="footer text-center mt-3 px-3 pb-4">
        <div class="text-left mx-auto" style="max-width: 1024px;">
          <h2
            style="font-size: font-size: 31px;font-weight:400 ;font-style:normal;font-family: 'Titillium Web, sans-serif'"
            v-html="data.footer.top.title[lang]"
          ></h2>
          <p
            style="font-size: 12px;line-height: 1.4;font-style: normal;font-family: 'Open Sans', sans-serif;color: #828282;"
            v-html="data.footer.top.subtitle[lang]"
          ></p>
          <b-row class="m-0 p-0">
            <b-col
              v-for="(item, index) in data.footer.items"
              cols="12"
              class="p-0 m-0 mt-1"
              md="6"
              :key="index"
            >
              <a v-if="item.href" :href="item.href" style="color: inherit;">
                <b-row class="m-0 p-0">
                  <div
                    style="width: 50px;height: 50px;border-radius: 4px;padding: 12px;"
                    :style="{backgroundColor:data.themes.items[data.themes.selectedIndex].primary}"
                  >
                    <b-icon
                      :icon="item.icon"
                      class="w-100 h-100"
                      :style="{color:data.themes.items[data.themes.selectedIndex].onPrimary}"
                    ></b-icon>
                  </div>
                  <b-col class="py-0">
                    <h3
                      class="p-0 m-0 pt-1"
                      style="font-weight: 400;font-style: normal;font-family: 'Titillium Web', sans-serif;font-size: 20px;line-height: 0.8;letter-spacing: 0px;"
                    >{{ item.title[lang] }}</h3>
                    <p
                      class="p-0 m-0 pt-1"
                      style="font-weight: 400;font-style: normal;font-family: 'Open Sans', sans-serif;color: #828282;font-size: 16px;line-height: 1.4;letter-spacing: 0px;"
                    >{{ item.body[lang] }}</p>
                  </b-col>
                </b-row>
              </a>
              <b-row v-else class="m-0 p-0">
                <div
                  style="width: 50px;height: 50px;border-radius: 4px;padding: 12px;"
                  :style="{backgroundColor:data.themes.items[data.themes.selectedIndex].primary}"
                >
                  <b-icon
                    :icon="item.icon"
                    class="w-100 h-100"
                    :style="{color:data.themes.items[data.themes.selectedIndex].onPrimary}"
                  ></b-icon>
                </div>
                <b-col class="py-0">
                  <h3
                    class="p-0 m-0 pt-1"
                    style="font-weight: 400;font-style: normal;font-family: 'Titillium Web', sans-serif;font-size: 20px;line-height: 0.8;letter-spacing: 0px;"
                    v-html="item.title[lang]"
                  ></h3>
                  <p
                    class="p-0 m-0 pt-1"
                    style="font-weight: 400;font-style: normal;font-family: 'Open Sans', sans-serif;color: #828282;font-size: 16px;line-height: 1.4;letter-spacing: 0px;"
                    v-html="item.body[lang]"
                  ></p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-modal id="galory_modal" hide-footer size="xl">
        <b-carousel ref="myCarousel" :interval="0" controls label-next label-prev>
          <b-carousel-slide
            v-for="(val, key) in data.section_5.images"
            :key="key"
            :img-src="$baseUrl + val"
          ></b-carousel-slide>
        </b-carousel>
      </b-modal>
    </div>
  </div>
</template>    

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  computed: {
    data: {
      get: function() {
        return this.$store.state.data;
      }
    },
    lang: {
      get: function() {
        return this.$store.state.lang;
      },
      set: function(newVal) {
        this.$store.state.lang = newVal;
      }
    }
  },
  methods: {
    getWidthPersention(arr, index) {
      let cols;
      if (window.innerWidth > 767.98) {
        cols = 4;
      } else {
        cols = 2;
      }
      const lastRowCount = arr.length % cols;
      const rowIndex = parseInt(parseInt(index) / parseInt(cols));
      if (rowIndex == parseInt(parseInt(arr.length) / parseInt(cols))) {
        return 100 / lastRowCount;
      }
      return 100 / cols;
    },
    showGaloryModal(img) {
      const index = this.data.section_5.images.indexOf(img);
      this.$bvModal.show("galory_modal");
      this.$nextTick(() => {
        this.$refs.myCarousel.setSlide(index);
      });
    },
    onResize() {
      this.$forceUpdate();
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style>
:root {
  --primary: #eebd00;
  --primaryMid: #eebd0088;
}

@import url("../assets/button.css");
a:hover {
  text-decoration: none;
}
.s5-img:hover .s5-img-overlay {
  opacity: 1;
}

.s5-img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  /* background-color: var(--primaryMid); */
  font-size: 60px;
}

.ii-header {
  font-weight: 600;
  font-style: normal;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0px;
}

.ii-title {
  font-weight: 700;
  font-family: "Titillium Web", sans-serif;
  font-size: 20px;
  line-height: 1.4;
}

.ov-s1-p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.top-slider {
  position: relative;
  aspect-ratio: 2;
  width: 100%;
  overflow: hidden;
}

.ov-s1-h1 {
  font-size: 31px;
  line-height: 1.2;
  letter-spacing: 0px;
}

@media only screen and (max-width: 900px) {
  .top-slider {
    width: 100%;
    aspect-ratio: 1;
  }

  .ov-s1 {
    padding-bottom: 8px;
    padding-top: 8px;
  }

  .ov-s1-h1 {
    font-size: 25px;
    line-height: 1.2;
    letter-spacing: 0px;
  }

  .ov-s1-p {
    font-size: 14px;
  }

  .ov-s1 {
    right: 0;
  }
}

.ov-s1 {
  position: absolute;
  top: 10%;
  left: 0;
  z-index: 2;
  color: white;
  background-color: rgba(33, 33, 33, 0.88);
  background-position: left top;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
  padding-top: 50px;
}

.header {
  background-color: var(--primary);
}

.link {
  color: inherit;
}

.link:hover {
  color: white;
}

#app {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #434f53;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  background-color: #ffffff;
}
</style>
