import App from './App.vue'
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import Vuex from 'vuex'
import NImg from './components/NImg'
import NIcon from './components/NIcon'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    data:null,
    lang:'fr',
    images:null
  }
})
Vue.prototype.$baseUrl= 'https://inspect.infinitelimousine.com'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'
router.beforeEach( (to, from, next) => {
  if(!store.state.data){
    axios.get(Vue.prototype.$baseUrl + '/api/data').then(res => {
      store.state.data = res.data
      next()
    }).catch((e) => {
      console.error(e)
    })
  }
  else{
    next()
  }
})
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false
Vue.component('NImg', NImg)
Vue.component('NIcon',NIcon)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
